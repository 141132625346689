const list = `
the-gaming-llama
cmgsteems
iamgod
bl-transporter
braini
cracked-games
gautampartapsoni
jimreitz
bitchminer
nikapelex
cpcensorshiptest
coinbaseus
allenk
beeryallen
binancesupport
brbaramille
coinbase-usa
coinbasehelpline
coinbasehelps
coinbaseservice
coinbaseservices
coinbasesupp
coinbasesupportp
coinbasesupports
coinbasesupportt
coinbashelp
customerservice
ecencymaster
harryxosborn
jakelaw915
jonydevitis
karolinalowe
lylezmclean
onobel
perciejacksondxc
poilebraubragra
rarec44537
rewqas568
samuel2004
servicecoinbase
tklbidramu
usacoinbase
veronicaxwilson
veudufideprei
vfdbvfgbvdfgdf
zakiartist85y
`
    .trim()
    .split('\n');

export default list;
