const list = `
mateja.klaric
xondra
tgylhn
vichkovski
wizzymt
thedarkoverlord
twoblokestrading
ruttydm
mlcuk
m4r1a
bridgenit
bitchminer
bisade
boy666
casually
nayardu92
djdarkstorm
cristinaluchi
dennis.spiedt
sebtarnowski
mihailm
ardaia
jemand
chiefadu
nikapelex
itsben
bazik33
feizhousexcult
hazesociety
`
    .trim()
    .split('\n');

export default list;
